function listGeneral(list) {
	const { children, options } = list;
	list.alerts = [];

	// Check points
	if (list.cost > list.points) {
		list.alerts.push({ message: "Total list cost exceeds the selected points limit." })
	}

	if (options?.groups?.length) {
		for (const g of options.groups) {
			if (g.active === false) continue;

			let count = 0;
			if (g.groups) {
				count = g.groups.reduce((prev, s) => prev + s.orphans.filter(o => o.selected).length, 0);
			} else {
				count = g.orphans.filter(o => o.selected).length;
			}

			if (count > g.maxOptions) {
				list.alerts.push({ message: `Selected ${g.name} are over the maximum (${g.maxOptions}).` });
			}
			if (count < g.minOptions) {
				list.alerts.push({ message: `Selected ${g.name} are below the minimum (${g.minOptions}).` });
			}
		}
	}

	const officers = {};
	const regiments = {};
	let countSardars = 0;
	let imperialAcademies = 0;
	const upgrades = [
		"heirlooms",
		"mutations",
		"relics",
		"trove-finds",
		"artefacts",
		"treasures",
		"bestowed-relics",
		"patron-s-gifts",
	];
	const upgradeMap = {};

	children.forEach(c => {
		const { type, selections, children, entrySlug, listEntry } = c;

		if (entrySlug === "sardar") countSardars++;

		if (type === "regiment") {
			if (selections.includes("imperial-academies")) imperialAcademies += 1;
			regiments[listEntry.name] = regiments[listEntry.name] ? regiments[listEntry.name] + 1 : 1;
			children.forEach(o => {
				if (o.entrySlug === "sardar") countSardars++;
				if (o.type === "officer") {
					officers[o.listEntry.name] = officers[o.listEntry.name] ? officers[o.listEntry.name] + 1 : 1;
				}
			})
		} else {
			const { groups = [] } = listEntry.options || {};
			const upgradeGroups = groups.filter(g => upgrades.includes(g.slug)) || [];
			upgradeGroups.forEach(u => {
				const { groups: subgroups = [], orphans = [] } = u;
				orphans.forEach(o => {
					if (o.selected) {
						upgradeMap[o.name] = upgradeMap[o.name] ? upgradeMap[o.name] + 1 : 1;
					}
				});

				subgroups.forEach(g => {
					g.orphans.forEach(o => {
						if (o.selected) {
							upgradeMap[o.name] = upgradeMap[o.name] ? upgradeMap[o.name] + 1 : 1;
						}
					});
				});
			})
		}
	})

	for (const [key, val] of Object.entries(officers)) {
		if (val > 2) {
			list.alerts.push({ message: `Officer "${key}" appears more than twice in the list.` })
		}
	}

	for (const [key, val] of Object.entries(regiments)) {
		if (val > 4) {
			list.alerts.push({ message: `Regiment "${key}" appears more than four times in the list.` })
		}
	}

	if (imperialAcademies > 1) {
		list.alerts.push({ message: "Only one Regiment may be selected to benefit from Imperial Academies." })
	}

	if (countSardars > 1) {
		list.alerts.push({ message: "Only one Sardar may be included in the list." })
	}

	const keys = Object.keys(upgradeMap);
	keys.forEach(k => {
		if (upgradeMap[k] > 1) {
			list.alerts.push({ message: `${k} can only appear once in the Army.` });
		}
	});
	// if (options) {
	// 	const groups = options.groups || [];
	// 	for (const g of groups) {
	// 		if (g.active === false) continue;

	// 		const count = g.orphans.filter(o => o.selected).length;
	// 		if (count > g.maxOptions) {
	// 			list.alerts.push({ message: `Selected ${g.name} are over the maximum (${g.maxOptions}).` });
	// 		}
	// 		if (count < g.minOptions) {
	// 			list.alerts.push({ message: `Selected ${g.name} are below the minimum (${g.minOptions}).` });
	// 		}
	// 	}
	// }
}


export default listGeneral;