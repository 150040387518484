import { Chip, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import EntryCount from "../entry/EntryCount";
import DeleteIcon from '@mui/icons-material/Delete';
import { red, green } from '@mui/material/colors';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ReactComponent as WarlordIcon } from "../../assets/icons/warlord.svg";
import AlertButton from "../bits/AlertButton";
import EntryPaper from "../entry/EntryPaper";
import useGameSettings from "../../hooks/use-game-settings";
import { attachNode, removeNode } from "../../store/list-slice";
import { useDispatch, useSelector } from "react-redux/es";
import { getDuplicate } from "../../lib/list";
import HorPanelsCtx from "../../ui/layouts/HorPanelsCtx";
import { useContext } from "react";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ListEntryPanel from "./ListEntryPanel";
import RemoveIcon from '@mui/icons-material/Remove';

function getChildrenIds (node, ids = []) {
	ids.push(node.id);
	node.children.forEach(c => getChildrenIds(c, ids));
	return ids;
}


function ListEntryButton({ node, factionSlug, onSelect, onSwap, noActions, forceDelete }) {
	const { id, path, parent, listEntry, type: nodeType, alerts, cost } = node;
	const {
		name,
		slug,
		isWarlord,
		type,
		gameClass,
		// cost,
		size,
	} = listEntry;
	const dispatch = useDispatch();
	const list = useSelector(state => state.list);
	const { getEntrySettings, getFactionSettings } = useGameSettings();
	const icon = getEntrySettings(slug)?.icon ||
		getFactionSettings(factionSlug).defaultEntryIcon;
	const { onPanelAdd, onPanelRemove } = useContext(HorPanelsCtx);

	function handleClick() {
		if (onSelect) {
			onSelect();
		} else {
			// if (isSlotAvailable()) {
			// 	onPanelAdd({
			// 		id: entry.path,
			// 		content: <ListEntryPanel warbandId={warband.id} entryId={entry.id} onUpdate={onUpdate} listEntry={entry} path={path} />
			// 	});
			// } else {
			// 	setOpen(true);
			// }
			onPanelAdd({
				id: id,
				type: "flexible",
				type: "temporary",
				position: "right",
				closable: true,
				body: <ListEntryPanel panel={{ id, data: node }} />
			});
		}
	}

	function handleDelete(e) {
		const ids = getChildrenIds(node);
		ids.forEach(id => onPanelRemove(id));
		dispatch(removeNode({
			parentPath: parent.path,
			nodeId: id,
		}));
		e.stopPropagation();
	}

	function handleDuplicate(e) {
		const clone = getDuplicate(list, path);
		if (clone) {
			dispatch(attachNode({ path: parent.path, node: clone }));
		}
		e.stopPropagation();
	}

	let borderTint = null;
	if (nodeType === "mainstay") borderTint = green[700];
	else if (nodeType === "restricted") borderTint = red[700];

	return (
		<EntryPaper onClick={handleClick} borderTint={borderTint}>
			<Stack direction="row" spacing={0.5} className="content">
				<Box
					className="avatar"
					flex="0 0 auto"
					sx={{ background: `url(${icon}) center/cover` }}
				/>
				<Box position="relative" flex="1 1 auto" minWidth={0}>
					<Stack spacing={0.5} className="padded">
						<Stack direction="row" spacing={0.5} alignItems="center">
							{isWarlord && (
								<SvgIcon component={WarlordIcon} fontSize="small" />
							)}
							<Typography
								variant="h6"
								flexGrow={1}
								noWrap
								display="inline-block"
							>
								{name}
							</Typography>
						</Stack>
						<Stack direction="row" spacing={0.5}>
							<Chip label={type} size="small" />
							{gameClass && <Chip label={gameClass} size="small" />}
						</Stack>
					</Stack>
					<Stack
						direction="row"
						spacing={1}
						right={0}
						top="50%"
						position="absolute"
						sx={{
							transform: "translateY(-50%)"
						}}
					>
						{!!alerts.length && (
							<AlertButton key="4" alerts={alerts} concerning={name} />
						)}
						{nodeType !== "character" && !noActions && (
							<IconButton
								key="1"
								variant="light-overlay"
								onClick={handleDuplicate}
							>
								<ContentCopyIcon />
							</IconButton>
						)}
						{(nodeType !== "character" || forceDelete) && !noActions && (
							<IconButton
								key="2"
								variant="light-overlay"
								onClick={handleDelete}
							>
								<DeleteIcon />
							</IconButton>
						)}
						{onSwap && !noActions && (
							<IconButton
								key="3"
								variant="light-overlay"
								onClick={onSwap}
							>
								<SyncAltIcon />
							</IconButton>
						)}

					</Stack>
				</Box>
				<Stack
					justifyContent="space-between"
					alignItems="flex-end"
					className="padded"
					flex="0 0 auto"
				>
					<Typography variant="h6">{(cost === "*" || nodeType === "officer" || nodeType === "retinue" ? <RemoveIcon fontSize="small" /> : null) || cost || "Free"}</Typography>
					<EntryCount count={size} />
				</Stack>
			</Stack >
		</EntryPaper >
	);
}


export default ListEntryButton;