const FB_RULES = {
	"LIMIT_OPTIONS": (node) => {
		const { listEntry, alerts } = node;
		const filter = o => o.selected;

		if (listEntry) {
			const { options, entrySelectors } = listEntry;
			if (options?.groups?.length) {
				for (const g of options.groups) {
					if (g.active === false) continue;

					let count = 0;
					if (g.groups) {
						count = g.groups.reduce((prev, s) => prev + s.orphans.filter(filter).length, 0);
					} else {
						count = g.orphans.filter(filter).length;
					}

					if (count > g.maxOptions) {
						alerts.push({ message: `Selected ${g.name} are over the maximum (${g.maxOptions}).` });
					}
					if (count < g.minOptions) {
						alerts.push({ message: `Selected ${g.name} are below the minimum (${g.minOptions}).` });
					}
				}
			}

			if (entrySelectors) {
				for (const s of entrySelectors) {
					const { active, selections = [], targets = [], minOptions, name } = s;
					if (active === false) continue;
					const targetIds = targets.map(t => t.id);
					const actualSelections = selections.filter(s => targetIds.includes(s))
					if (actualSelections.length < minOptions) {
						alerts.push({ message: `${name} requires a valid target.` });
					}
				}
			}
		}
	},
	"CHARACTER": (node) => {
		const { listEntry: character, alerts, selections } = node;
		const { options, tagGroups } = character;

		let banners = 0;
		let weapons = 0;
		let talismans = 0;
		let armors = 0;
		let arcane = 0;

		const itemCategories = ["heirlooms", "artefacts", "trove-finds", "treasures", "relics", "bestowed-relics", "patron-s-gifts"];
		const group = options.groups.find(g => itemCategories.includes(g.slug));
		if (group) {
			const bannersGroup = group.groups?.find(g => g.slug === "banners")
			if (bannersGroup) {
				banners = bannersGroup.orphans.filter(o => o.selected).length
			}

			const weaponsGroup = group.groups?.find(g => g.slug === "weapons")
			if (weaponsGroup) {
				weapons = weaponsGroup.orphans.filter(o => o.selected).length
			}

			const armorsGroup = group.groups?.find(g => g.slug === "armors")
			if (armorsGroup) {
				armors = armorsGroup.orphans.filter(o => o.selected).length
			}

			const talismansGroup = group.groups?.find(g => g.slug === "talismans")
			if (talismansGroup) {
				talismans = talismansGroup.orphans.filter(o => o.selected).length
			}

			const arcaneGroup = group.groups?.find(g => g.slug === "arcane")
			if (arcaneGroup) {
				arcane = arcaneGroup.orphans.filter(o => o.selected).length
			}
		}

		if (banners > 1 || weapons > 1 || armors > 1 || talismans > 1 || arcane > 1) {
			alerts.push({ message: `Selected ${group.name} must belong in different categories` });
		}


		// let tactical = 0;
		// const retinue = options.groups.find(g => g.slug === "retinue");
		// if (retinue) {
		// 	const tacticalGroup = retinue.groups?.find(g => g.slug === "tactical")
		// 	if (tacticalGroup) {
		// 		tactical = tacticalGroup.orphans.reduce((prev, o) => prev + (o.size || 0), 0);
		// 	}
		// }

		// if (banners > 0 && tactical < 1) {
		// 	alerts.push({ message: "Banners require at least one Tactical Retinue model." });
		// }
	},
	"ADDITIONAL_SPELLS": (node) => {
		const { alerts, selections } = node;

		if (
			(selections.includes("school-of-fire") && (selections.includes("kindle-courage") || selections.includes("fire-dart"))) ||
			(selections.includes("school-of-water") && (selections.includes("ninuah-s-tears") || selections.includes("call-fog"))) ||
			(selections.includes("school-of-earth") && (selections.includes("earth-to-mud") || selections.includes("stone-spikes"))) ||
			(selections.includes("school-of-air") && (selections.includes("seeking-winds") || selections.includes("guide")))
		) {
			alerts.push({ message: "Additional spells must come from a different School." });
		}
	},
	"REQUIRES_WARLORD": (node, params, faction) => {
		const { entrySlugs } = params;
		const { alerts, children } = node;
		const warlordSlugs = [];

		children.forEach(w => {
			const character = w.children.find(c => c.type === "character");
			if (character && character.listEntry.isWarlord) {
				warlordSlugs.push(character.listEntry.slug);
			}
		});
		if (!warlordSlugs.some(s => entrySlugs.includes(s))) {
			const entryNames = entrySlugs.map(s => faction.entries[s].name);
			alerts.push({ message: "One of the following Characters must be selected to be the Warlord: " + entryNames.join(", ") });
		}
	},
	"STANDARD_BEARER": (node, params, faction) => {
		const { listEntry, selections, alerts, sizeSteps = 0 } = node;
		const { threshold = 99 } = params;
		const officerGroup = listEntry.options?.groups?.find(g => g.slug === "officers");
		let officerCount = 0;
		if (officerGroup) {
			officerCount = officerGroup.orphans.filter(o => o.selected).length
		}
		if (listEntry.size + officerCount < threshold && selections.includes("standard-bearer")) {
			alerts.push({ message: `A Standard Bearer requires at least ${threshold} models.` })
		}
	},
	"LEADER_THRESHOLD": (node, params, faction) => {
		const { listEntry, selections, alerts, sizeSteps = 0 } = node;
		const { threshold = 99 } = params;
		if (listEntry.size < threshold && selections.includes("leader")) {
			alerts.push({ message: `A Leader requires at least ${threshold} models.` })
		}
	},
	"BOUND_ELEMENTALS": (node) => {
		const { selections, alerts } = node;
		const countDancer = selections.filter(s => s === "bound-elemental-efreet-sword-dancer").length;
		const countDjinn = selections.filter(s => s === "bound-elemental-steelheart-djinn").length;
		if (
			countDancer > 1 ||
			countDjinn > 1 ||
			(countDancer > 0 && countDjinn > 0)
		) alerts.push({ message: "The Character Regiment may only include 1 Bound Elemental Retinue Model of any kind." })
	},
};


export default FB_RULES;